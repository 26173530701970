<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pos-rlt pt-md-6 mb-16 d-none d-md-block">
      <v-card class="elevation-0 px-16 py-10 rounded-xl pos-rlt" color="#E5EBEC">
        <p class="fs-50 font-weight-bold mb-0">Zebu Blogs</p>
        <div class="d-inline-flex mb-6">
          <v-btn @click="activePage((i = p))" v-for="(p, b) in blogitems" :key="b" medium color="#666666"
            class="rounded-pill mr-2 text-none brd-2" outlined>
            {{ p.txt }}
          </v-btn>
        </div>
        <BlogSearch @setParentComponentDetails="setDetailsForComponent"></BlogSearch>

        <div>
          <img class="pos-abs calc-img text-right" alt="main-bg.png" src="@/assets/blog/main-bg.png" width="35%" />
        </div>
        <div class="pos-abs learn-img">
          <img alt="learn-bg.svg" src="@/assets/learn-bg.svg" width="172px" />
        </div>
      </v-card>
    </div>

    <div class="py-6 pb-md-16">
      <v-container class="px-0 px-md-16">
        <div class="mx-auto text-md-center px-6 px-sm-8 px-md-12">
          <p class="mb-2 subtitle-2 font-weight-regular">
            <span class="cursor-p" @click="toHome()">Zebu Blogs</span> → <span class="cursor-p" @click="catBlog()">{{
              pagename.tit ? pagename.tit : "All" }}</span> → Blog
          </p>

          <p class="mb-6 font-weight-bold secondary-infont fs-32 d-none d-md-block">
            {{ detailblog.attributes ? detailblog.attributes.blog_title : "" }}
          </p>
          <p class="mb-6 font-weight-bold secondary-infont fs-28 d-md-none">
            {{ detailblog.attributes ? detailblog.attributes.blog_title : "" }}
          </p>
          <v-divider></v-divider>
          <v-row no-glutters class="py-2">
            <v-col cols="6" class="text-left">
              <p class="mb-0 subtitle-2 font-weight-regular">by {{ detailblog.attributes ?
                detailblog.attributes.blog_author : "" }}</p>
            </v-col>
            <v-col cols="6" class="text-right">

              <p class="mb-0 subtitle-2 font-weight-regular">
                <span class="pt-2" v-if="blogpaid">
                  Last Updated:
                  {{
                    new Date(detailblog.attributes.publishedAt)
                      .toDateString()
                      .slice(4) + ' ' +
                    new Date(detailblog.attributes.publishedAt)
                      .toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }) + ' IST'
                  }}
                </span>
                <span v-else class="mt-14">Last Updated: {{ detailblog.attributes
                  ? detailblog.attributes.publishedAt : "" }} </span>

                <v-tooltip color="black" bottom>
                  <template v-slot:activator="{ on, attrs }"> <v-btn class="ml-3" @click="shareViaWebShare"
                      v-bind="attrs" v-on="on" fab x-small icon><v-icon
                        size="25">mdi-share-variant-outline</v-icon></v-btn>
                  </template>
                  <span>Share</span>
                </v-tooltip>
              </p>
            </v-col>
          </v-row>
          <v-card class="rounded-0 elevation-0">
            <div v-if="blogpaid">
              <img
                :src="detailblog.attributes ? 'https://sess.mynt.in/strapi/' + detailblog.attributes.blog_image.data[0]?.attributes.formats.large.url : ''"
                width="100%"
                :alt="detailblog.attributes ? 'https://sess.mynt.in/strapi/' + detailblog.attributes.blog_image.data[0]?.attributes.formats.large.url : ''" />
            </div>
            <div v-else>
              <img :src="detailblog.attributes ? detailblog.attributes.img : ''" width="100%"
                :alt="detailblog.attributes ? detailblog.attributes.img : ''" />

            </div>

          </v-card>
          <div class="text-left py-4 px-2 px-sm-4"
            v-html="detailblog.attributes ? setFormatter(detailblog.attributes.blog_description) : ''"></div>
          <v-divider></v-divider>
          {{ title }}
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { strapi } from "../../apiurl";
import BlogSearch from "../Blogs/BlogSearch.vue";

export default {
  data: () => ({
    page: 1,
    pagecount: 0,
    catpagecount: 0,

    blogitems: [],

    pagename: {},

    allblogs: [],
    catblogs: [],

    detailblog: {},
    mainblog: {},
    mainside: [],
    mainslide: [],
    imgurl: "http://192.168.5.22:1337",
    routparmsid: "",
    blogpaid: "",
    rotparmsshareid: "",
    // imgurl: 'https://strapi.zebull.in',
    // props: ['title'],

  }),
  components: {
    BlogSearch,
  },

  mounted() {
    const routeParam = this.$route.params.title;
    const idMatch = routeParam.match(/-id(\d+)$/);
    if (idMatch && idMatch[1]) {
      this.blogId = idMatch[1];
      this.blogTitle = decodeURIComponent(routeParam.replace(/-id\d+$/, '').replace(/-/g, ' '));
      const routeParams = this.$route.params || {};
    const queryId = this.blogId || null;
    this.rotparmsshareid = this.$route.params
    this.blogpaid = this.blogId
    const handleFallback = () => {
      try {
        const storedData = JSON.parse(localStorage.getItem("50bf"));
        if (storedData && storedData.attributes) {
          this.pagename = storedData.attributes.pagename;
          this.detailblog = storedData;
          this.getCategorie();
          this.scrollBehavior();
        } else {
          this.$router.push("/blogs");
        }
      } catch (error) {
        console.error("Error parsing local storage data:", error);
        this.$router.push("/blogs");
      }
    };

    if (queryId) {
      let catdata = [];

      import('axios').then(({ default: axios }) => {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          // url: `https://sess.mynt.in/strapi/blogs/${queryId}`,
          url: `https://sess.mynt.in/strapi/blogs/${queryId}?populate[blog_image]=*`,
          headers: {},
        };
        axios.request(config)
          .then((response) => {
            const errordata = response.data
            const apiData = response.data?.data;
            if (apiData?.id) {
              for (let i = 0; i < apiData.length; i++) {
                apiData[i].attributes["img"] = `${strapi}${apiData[i].attributes.blog_image.data[0].attributes.url}`;
                apiData[i].attributes.publishedAt = `${new Date(apiData[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(apiData[i].attributes.publishedAt).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} IST`;
                catdata.push(apiData[i]);
                console.log(apiData);

              }
              console.log(apiData);

              localStorage.setItem("50bf", JSON.stringify(apiData));
              this.pagename = apiData.attributes?.pagename || "Default Page";
              this.detailblog = apiData;
              this.getCategorie();
              this.scrollBehavior();
            } else {
              if (errordata.error.message == 'Not Found') {
                this.$router.push("/blogs");

              }
              else {
                handleFallback();
              }
            }
          })
          .catch((error) => {
            console.error("Error fetching data from API:", error);
            handleFallback();
          });
      });
    } else {
      if (routeParams.id) {
        localStorage.setItem("50bf", JSON.stringify(routeParams));
        this.pagename = routeParams.attributes?.pagename || "Default Page";
        this.detailblog = routeParams;
        this.getCategorie();
        this.scrollBehavior();
      } else {

        if (routeParams.id == undefined) {
          this.$router.push("/blogs");
        }
        else {
          handleFallback();

        }
      }
    }

    } else {
      console.error('Blog ID not found in the URL');
      this.$router.push("/blogs");
    }
  },




  methods: {

    setFormatter(para) {
      const formattedData = para
        // Replace headings
        .replace(/^# (.*?)$/gm, "<h1>$1</h1>")
        .replace(/^## (.*?)$/gm, "<h2>$1</h2>")
        .replace(/^### (.*?)$/gm, "<h3>$1</h3>")
        .replace(/^#### (.*?)$/gm, "<h4>$1</h4>")
        .replace(/^##### (.*?)$/gm, "<h5>$1</h5>")
        .replace(/^###### (.*?)$/gm, "<h6>$1</h6>")
        // Replace blockquotes (lines starting with ">")
        .replace(/^> (.*?)$/gm, "<blockquote>$1</blockquote>")
        // Replace inline bold text (double asterisks or double underscores)
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
        .replace(/__(.*?)__/g, "<strong>$1</strong>")
        // Replace inline italic text (single asterisk or single underscore)
        .replace(/\*(.*?)\*/g, "<em>$1</em>")
        .replace(/_(.*?)_/g, "<em>$1</em>")
        // Replace inline underlined text
        .replace(/<u>(.*?)<\/u>/g, '<span style="text-decoration: underline;">$1</span>')
        // Replace inline code blocks (`code`)
        .replace(/`(.*?)`/g, "<code>$1</code>")
        // Replace links ([text](url))
        .replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>')
        // Handle unordered lists (lines starting with "- " or "* ")
        .replace(/^[-*] (.*?)$/gm, "<ul><li>$1</li></ul>")
        // Handle ordered lists (lines starting with number + ". ")
        // .replace(/^\d+\.\s*(.*?)$/gm, "<ol><li>$1</li></ol>")
        // Replace double line breaks with closing and opening paragraph tags
        .replace(/\n\s*\n/g, "</p><p>")
        // Wrap the entire text in opening and closing paragraph tags
        .replace(/^(?!<h1>|<h4>|<blockquote>|<ul>|<ol>|<\/p>|<\/h1>|<\/h4>|<\/blockquote>)(.+)$/gm, "<p>$1</p>");

      return formattedData;
    },
    scrollBehavior() {
      window.scrollTo(0, 0);
    },
    activePage(i) {
      this.$router.push({ name: "blog category", params: i });
    },
    getCategorie() {
      this.blogitems = [];
      this.blogitems = [{ tit: "All", txt: "All", btn: "Show all blogs", val: "all", id: 1 }];
      let config = {
        method: "get",
        url: `${strapi}/blog-categories`,
      };

      let axiosThis = this;
      axios
        .request(config)
        .then((response) => {
          if (response.data.data) {
            let data = response.data.data;
            for (let c = 0; c < data.length; c++) {
              let cconfig = {
                method: "get",
                url: `${strapi}/blogs?filters[blog_categories][name][$eq]=${data[c].attributes.name}&sort[0]=blogdate:desc&pagination[pageSize]=${4}&populate[blog_image]=*`,
              };
              let catdata = [];
              axios
                .request(cconfig)
                .then((response) => {
                  if (response.data.data) {
                    let datas = response.data.data;
                    for (let i = 0; i < datas.length; i++) {
                      datas[i].attributes["img"] = `${strapi}${datas[i].attributes.blog_image.data[0].attributes.url}`;
                      datas[i].attributes.publishedAt = `${new Date(datas[i].attributes.publishedAt).toDateString().slice(3)} ${new Date(datas[i].attributes.publishedAt).toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })} IST`;
                      catdata.push(datas[i]);
                    }
                  } else {
                    catdata = [];
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
              axiosThis.blogitems.push({ tit: data[c].attributes.name, txt: data[c].attributes.name.split(" ", 1)[0], btn: `More ${data[c].attributes.name} blog`, val: data[c].attributes.name.slice(0, 3), id: c + 2, data: catdata });
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    catBlog() {
      var item = this.blogitems.find((o) => o.val === this.pagename.val);
      if (item == undefined) {
        this.pagename = this.blogitems.find((o) => o.val === "All");
      } else {
        this.pagename = item;
      }
      var i = this.pagename;
      this.activePage(i);
    },
    toHome() {
      this.$router.push("/blogs");
    },
    setDetailsForComponent() {
      this.detailblog = {};
      let b = JSON.parse(localStorage.getItem("50bf"));
      this.pagename = b.attributes.pagename;
      this.detailblog = b;
      this.getCategorie();
      this.scrollBehavior();
    },
   
    shareViaWebShare() {
  const formattedTitle = this.detailblog.attributes.blog_title
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '');

  const blogUrl = `${window.location.origin}/blog/${formattedTitle}-id${this.detailblog.id}`;

  // Use Web Share API
  navigator.share({
    title: "Zebu",
    text: "Zebu offers trading and investment opportunities. Create an account right away.",
    url: blogUrl,
  }).catch((error) => {
    console.error('Error using Web Share API:', error);
  });
},

  },
};
</script>
