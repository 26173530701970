<template>
    <div class="no-xhide">
        <div data-aos="fade-up">
            <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl d-none d-md-block">
                <p class="white--text fs-50 font-weight-bold mb-6">Depository Investor Charter</p>
                <p class="white--text headline text-capitalize">Investor Charter For Depositories <br> And Depository
                    Participants
                </p>
            </v-card>
            <v-card class="elevation-0 px-6 px-sm-8 py-10 text-center support-main-bg rounded-0 d-md-none">
                <p class="white--text fs-38 lh-38 font-weight-bold mb-6">Depository Investor Charter</p>
                <p class="white--text fs-18 text-capitalize">Investor Charter For Depositories And Depository
                    Participants
                </p>
            </v-card>
        </div>
        <div class="pa-6 px-sm-8 px-md-14 py-md-16 mb-6">
            <p class="mb-0 font-weight-bold title">1. Vision</p>
            <p class="font-weight-light lh-28">Towards making Indian Securities Market - Transparent, Efficient, & Investor
friendly by providing safe, reliable, transparent and trusted record keeping platform
for investors to hold and transfer securities in dematerialized form.</p>
            <p class="mb-0 font-weight-bold title">2. Mission</p>
            <p class="font-weight-light lh-28">

            <ul>
                <li>To hold securities of investors in dematerialised form and facilitate its transfer,
                    while ensuring safekeeping of securities and protecting interest of investors.</li>
                <li>To provide timely and accurate information to investors with regard to their
holding and transfer of securities held by them.
</li>
                <li>To provide the highest standards of investor education, investor awareness and
timely services so as to enhance Investor Protection and create awareness about
Investor Rights.</li>
            </ul>
            </p>
            <p class="mb-0 font-weight-bold title">3. Details of business transacted by the Depository and Depository
                Participant (DP)</p>
            <p class="font-weight-light lh-28">A Depository is an organization which holds securities of investors in electronic
form. Depositories provide services to various market participants - Exchanges,
Clearing Corporations, Depository Participants (DPs), Issuers and Investors in
both primary as well as secondary markets. The depository carries out its activities
through its agents which are known as Depository Participants (DP). Details
available on the link <a target="_blank" rel="noopener noreferrer" class="mailto-txt font-weight-medium"
                    href="https://www.cdslindia.com/DP/dplist.aspx">https://www.cdslindia.com/DP/dplist.aspx</a></p>

            <p class="mb-0 font-weight-bold title">4. Description of services provided by the Depository through Depository
                Participants (DP) to investors </p>
            <p class="font-weight-light lh-28">(1) Basic Services</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="5" md="4"><span class="font-weight-bold subtitle-1">Brief about the Activity /
                            Service</span></v-col>
                    <v-col cols="7"><span class="font-weight-bold subtitle-1">Expected Timelines for processing by the
                            DP after receipt of proper documents</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in basicservices" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="5" md="4"><span class="font-weight-regular txt-444">{{ l.activiserv
                        }}</span></v-col>
                        <v-col cols="7"><span class="font-weight-regular txt-444"  v-html="l.timelines"></span></v-col>
                    </v-row>
                    <v-divider v-if="basicservices.length - 1 != j"></v-divider>
                </div>
            </v-card>
            <p class="font-weight-light lh-28">(2) Depositories provide special services like pledge, hypothecation, internet
                based services etc. in addition to their core services and these include</p>

            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="4" md="3"><span class="font-weight-bold subtitle-1">Type of Activity
                            /Service</span></v-col>
                    <v-col cols="8"><span class="font-weight-bold subtitle-1">Brief about the Activity /
                            Service</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in depositpovi" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="4" md="3"><span class="font-weight-regular txt-444">{{ l.typeact }}</span></v-col>
                        <v-col cols="8"><span class="font-weight-regular txt-444" v-html="l.breifabo"></span></v-col>
                    </v-row>
                    <v-divider v-if="depositpovi.length - 1 != j"></v-divider>
                </div>
            </v-card>
            <!-- <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="2" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="4"><span class="font-weight-bold subtitle-1">Type of Activity /Service</span></v-col>
                    <v-col cols="8" md="6"><span class="font-weight-bold subtitle-1">Brief about the Activity /
                            Service</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in emtrtab" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="2" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="4"><span class="font-weight-regular txt-444">{{ l.typeofser }}</span></v-col>
                        <v-col cols="8" md="6"><span class="font-weight-regular txt-444"
                                v-html="l.breifser"></span></v-col>
                    </v-row>
                    <v-divider v-if="emtrtab.length - 1 != j"></v-divider>
                </div>
            </v-card> -->

            <p class="mb-0 font-weight-bold title">5. Details of Grievance Redressal Mechanism</p>
            <p class="font-weight-light lh-28">(1) The Process of investor grievance redressal</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <div v-for="(l, j) in detagrievance" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="2" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="5" md="3"><span class="font-weight-regular txt-444">{{ l.invername }}</span></v-col>
                        <v-col cols="7"><span class="font-weight-regular txt-444" v-html="l.content"></span></v-col>
                    </v-row>
                    <v-divider v-if="detagrievance.length - 1 != j"></v-divider>
                </div>
            </v-card>
            <!-- <p class="font-weight-light lh-28">(2) <a target="_blank" rel="noopener noreferrer" class='mailto-txt font-weight-light' href='/investordps'>For the
                    Multi-level complaint resolution mechanism
                    available at the Depositories<sup>9</sup></a></p> -->
            <p class="font-weight-bold title">6. Guidance pertaining to special circumstances related to market activities:
                Termination of the Depository Participant</p>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-xl overflow-hidden mb-6">
                <v-row class="pa-4">
                    <v-col cols="1" class="d-none d-md-block"><span class="font-weight-bold subtitle-1">Sr.no</span></v-col>
                    <v-col cols="6"><span class="font-weight-bold subtitle-1">Type of special
                            circumstances</span></v-col>
                    <v-col cols="6" md="5"><span class="font-weight-bold subtitle-1">Timelines for the Activity/
                            Service</span></v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-for="(l, j) in guidencepre" :key="j">
                    <v-row class="pa-4">
                        <v-col cols="1" class="d-none d-md-block"><span class="font-weight-regular txt-444">{{ l.sr
                        }}</span></v-col>
                        <v-col cols="6"><span class="font-weight-regular txt-444" v-html="l.splcir"></span></v-col>
                        <v-col cols="6" md="5"><span class="font-weight-regular txt-444">{{ l.timelsact }}</span></v-col>
                    </v-row>
                    <v-divider v-if="guidencepre.length - 1 != j"></v-divider>
                </div>
            </v-card>

            <p class="font-weight-light title">7. <a target="_blank" rel="noopener noreferrer" class='mailto-txt font-weight-light' href='/investordps'>Dos and Don'ts
                    for Investors</a></p>
            <p class="font-weight-light title">8. <a  target="_blank" rel="noopener noreferrer"  class='mailto-txt font-weight-light' href='/investordps'>Rights of
                    investors</a></p>
            <p class="font-weight-light title">9. <a  target="_blank" rel="noopener noreferrer"  class='mailto-txt font-weight-light'
                    href='/investordps'>Responsibilities of Investors</a></p>

                    <p class="font-weight-light title">10. <a  target="_blank" rel="noopener noreferrer"  class='mailto-txt font-weight-light' href='/investordps'>Code of Conduct for Depositories (Part D of Third Schedule of SEBI (D & P) regulations, 2018)</a></p>
            <p class="font-weight-light title">11. <a  target="_blank" rel="noopener noreferrer"  class='mailto-txt font-weight-light'
                    href='/investordps'>Code of Conduct for Participants Responsibilities of Investors (Part A of Third Schedule of SEBI (D & P) regulations, 2018)</a></p>

         
        </div>
    </div>
</template>

<script>
export default {

    data: () => ({
        basicservices: [
            {
                sr: '1',
                activiserv: 'Dematerialization of securities',
                timelines: '7 days',
            },
            {
                sr: '2',
                activiserv: 'Rematerialization of securities',
                timelines: '7 days',
            },
            {
                sr: '3',
                activiserv: 'Mutual Fund Conversion / Destatementization',
                timelines: '5 days',
            },
            {
                sr: '4',
                activiserv: 'Re-conversion / Restatementisation of Mutual fund units',
                timelines: '7 days',
            },
            {
                sr: '5',
                activiserv: 'Transmission of securities',
                timelines: '7 days',
            },
            {
                sr: '6',
                activiserv: 'Registering pledge request',
                timelines: '15 days',
            },
            {
                sr: '7',
                activiserv: 'Closure of demat account',
                timelines: '7 days',
            },
            {
    sr: "8",
    activiserv: "Settlement Instruction",
    timelines: "For T+1 day settlements, Participants shall accept instructions from the Clients, in physical form up to 4 p.m. (in case of electronic instructions up to 6.00 p.m.) on T day for pay-in of securities. <br> For T+0 day settlements, Participants shall accept EPI instructions from the clients, till 11:00 AM on T day.<br> Note: ‘T’ refers to ‘Trade Day’"
}

        ],
        depositpovi: [
            {
                sr: "1",
                typeact: "Value Added Services",
                breifabo: "Depositories also provide value added services such as <br>a. <a  target='_blank' rel='noopener noreferrer'  class='mailto-txt font-weight-medium' href='/investordps'>Basic Services Demat Account(BSDA)<sup>1</sup></a><br>b. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>Transposition cum dematerialization<sup>2</sup></a><br>c. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'> Linkages with Clearing System<sup>3</sup></a><br>d. Distribution of cash and non-cash corporate benefits (Bonus, Rights, IPOs etc.), stock lending, demat of NSC / KVP, demat of warehouse receipts etc."
            },
            {
                sr: "2",
                typeact: "Consolidated Account statement (CAS)",
                breifabo: "CAS is issued 10 days from the end of the month (if there were transactions in the previous month) or half yearly(if no transactions) ."
            },
            {
                sr: "3",
                typeact: "Digitalization of services provided by the depositories",
                breifabo: "Depositories offer below technology solutions and e-facilities to their demat account holders through DPs: <br> a. <a class='mailto-txt font-weight-medium' rel='noopener noreferrer' href='/investordps'>E-account opening <sup>4</sup></a><br> b. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>Online instructions for execution <sup>5</sup></a><br> c. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>e-DIS / Demat Gateway<sup>6</sup></a><br>d. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'>e-CAS facility <sup>7</sup></a><br> e. <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='/investordps'> Miscellaneous services <sup>8</sup></a><br>"
            },
        ],
       
        detagrievance: [
            {
                sr: '1',
                invername: "Investor Complaint/Grievances",
                content: "<p class='mb-0 font-weight-light lh-28'>Investor can lodge complaint/ grievance against the Depository/DP in the following ways:</p><br><p class='mb-0 font-weight-light lh-28'>a. Electronic mode -</p><br><p class='mb-0 font-weight-light lh-28'>(i) SCORES 2.0 (a web based centralized grievance redressal system of SEBI)</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='https://scores.sebi.gov.in/'>https://scores.sebi.gov.in/</a> <br> <p class='mb-0 text-decoration-underline font-weight-bold lh-28'> Two Level Review for complaint/grievance against DP:</p>  <p class='mb-0 font-weight-light lh-28'>- First review done by Designated Body</p>  <p class='mb-0 font-weight-light lh-28'>- Second review done by SEBI</p>  <br> <p class='mb-0 font-weight-light lh-28'>(ii) Respective Depository's web portal dedicated for the filing of compliant</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='https://www.cdslindia.com/Footer/grievances.aspx'>https://www.cdslindia.com/Footer/grievances.aspx</a> <br><br> <p class='mb-0 font-weight-light lh-28'>(iii) Emails to designated email IDs of Depository</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='mailto: complaints@cdslindia.com'>complaints@cdslindia.com</a> <br><br> <p class='mb-0 font-weight-light lh-28'>b) Offline mode</p><a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href='mailto: complaints@cdslindia.com'>complaints@cdslindia.com</a> <br><br> <p class='mb-0 font-weight-light lh-28'>The complaints/ grievances lodged directly with the Depository shall be resolved within 21 days.</p>"
            },
            {
                sr: '2',
                invername: "Online Dispute Resolution (ODR) platform for online Conciliation and Arbitration",
                content: " <p class='mb-0 font-weight-light lh-28'>If the Investor is not satisfied with the resolution provided by DP or other Market Participants, then the Investor has the option to file the complaint/ grievance on SMARTODR platform for its resolution through by online conciliation or abitration.</p> <a target='_blank' rel='noopener noreferrer' class='mailto-txt font-weight-medium' href=' https://smartodr.in/login'> https://smartodr.in/login</a>",
            },
            {
                sr: '3',
                invername: "Steps to be followed in ODR for Review, Conciliation and Arbitration",
                content: " <p class='mb-0 font-weight-light lh-28'><ul><li>Investor to approach Market Participant for redressal of complaint.</li><li>If investor is not satisfied with response of Market Participant, he/she can escalate the complaint on SEBI SCORES portal.</li><li>Alternatively, the investor may also file a complaint on SMARTODR portal for its resolution through online conciliation and arbitration.</li><li>Upon receipt of complaint on SMARTODR portal, the relevant MII will review the matter and endeavour to resolve the matter between the Market Participant and investor within 21 days.</li><li>If the matter could not be amicably resolved, then the Investor may request the MII to refer the matter case for conciliation.</li><li>During the conciliation process, the conciliator will endeavor for amicable settlement of the dispute within 21 days, which may be extended with 10 days by the conciliator.</li><li>If the conciliation is unsuccessful, then the investor may request to refer the matter for arbitration.</li><li>The arbitration process to be concluded by arbitrator(s) within 30 days, which is extendable by 30 days.</li></ul></p>",
            },
        ],
        guidencepre: [
            {
                sr: "1",
                splcir: "<ul><li>Depositories to terminate the participation in case a participant no longer meets the eligibility criteria and/or any other grounds as mentioned in the bye laws like suspension of trading member by the Stock Exchanges.</li><li>Participant surrenders the participation by its own wish.</li></ul>",
                timelsact: "Client will have a right to transfer all its securities to any other Participant of its choice without any charges for the transfer within 30 days from the date of intimation by way of letter/email.",
            }
        ],
        responsiinvertab2: [
            {
                sn: "1",
                recivedfrn: "Directly from investor",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "2",
                recivedfrn: "SEBI(Scores)",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "3",
                recivedfrn: "Depositories",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "4",
                recivedfrn: "OtherSources(if any)",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
            {
                sn: "5",
                recivedfrn: "Grand Total",
                carrie: "0",
                receidur: "0",
                total: "0",
                resol: "0",
                prndiless: '0',
                prndimore: "0",
                avaer: "0",
            },
        ],
        trendmontab3: [
            {
                sn: '1',
                month: 'Jan/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '2',
                month: 'Feb/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '3',
                month: 'Mar/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '4',
                month: 'Apr/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '5',
                month: 'May/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '6',
                month: 'Jun/22',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
            {
                sn: '',
                month: 'Grand Total',
                carrfor: "0",
                recived: "0",
                resolve: "0",
                pending: "0",
            },
        ],
        trendannutab4: [
            {
                sn: '1',
                year: "2016-17",
                carrfowa: '0',
                receivedur: "4",
                resoldur: "4",
                pendingat: "0",
            },
            {
                sn: '2',
                year: "2017-18",
                carrfowa: '0',
                receivedur: "0",
                resoldur: "0",
                pendingat: "0",
            },

            {
                sn: '3',
                year: "2018-19",
                carrfowa: '0',
                receivedur: "1",
                resoldur: "1",
                pendingat: "0",
            },
            {
                sn: '4',
                year: "2019-20",
                carrfowa: '0',
                receivedur: "0",
                resoldur: "0",
                pendingat: "0",
            },
            {
                sn: '5',
                year: "2020-21",
                carrfowa: '0',
                receivedur: "1",
                resoldur: "1",
                pendingat: "0",
            },
            {
                sn: '',
                year: 'Grand Total',
                carrfowa: "0",
                receivedur: "6",
                resoldur: "6",
                pendingat: "0",
            },

        ],
    }),
}
</script>