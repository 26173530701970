<template>
  <div class="no-xhide">
    <div data-aos="fade-up" class="pt-4">
      <v-card class="elevation-0 px-16 py-10 support-main-bg rounded-xl pos-rlt d-none d-md-block">
        <p class="white--text fs-50 font-weight-bold mb-6">Stocks & ETFs</p>
        <p class="white--text fs-22">
          Invest in Stocks for Long-Term Wealth.<br>
          Own shares of top companies, earn dividends and see steady growth.<br>
          Start building your financial future today!
        </p>

        <div class="pos-abs prd-stk-img text-right">
          <img alt="main-bg.png" src="@/assets/product/stk/main-bg.png" width="90%" />
        </div>
      </v-card>
      <v-card class="elevation-0 px-6 px-sm-8 py-10 support-main-bg rounded-0 d-md-none text-center">
        <p class="white--text fs-38 font-weight-bold mb-6">Stocks & ETFs</p>
        <p class="white--text fs-18">Invest in Stocks for Long-Term Wealth.
          Own shares of top companies, earn dividends and see steady growth.
          Start building your financial future today!</p>
      </v-card>
    </div>
    <div class="pt-8 pt-md-16 px-md-16 mb-6">
      <div class="px-16 d-none d-md-block">
        <p class="font-weight-medium black--text"><span class="display-1">Invest in Stocks for Long-Term Wealth</span>
        </p>
        <p class="txt-444 font-weight-light title lh-28">Own a part of leading companies, unlock potential dividends,
          and watch your investments grow steadily. Begin building your financial future today.</p>

        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
          color="#2A2A2A" class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
            class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
      </div>
      <div target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" class="px-6 px-sm-8 d-md-none">
        <p class="font-weight-medium black--text"><span class="title">Invest in Stocks for Long-Term Wealth</span></p>
        <p class="txt-444 font-weight-light fs-18 lh-28">Own a part of leading companies, unlock potential dividends,
          and watch your investments grow steadily. Begin building your financial future today.</p>

        <v-btn target="_blank" rel="noopener noreferrer" href="https://oa.mynt.in/?ref=zws" height="48px"
          color="#2A2A2A" block class="text-none rounded-pill elevation-0 mb-10 mt-4"><span
            class="white--text subtitle-1 font-weight-medium px-2">Start investing</span></v-btn>
      </div>

      <div class="px-6 px-sm-8 px-md-16 mb-6">
        <v-card width="100%" data-aos="fade-down" class="box-s1 elevation-0 rounded-xl pt-16 pb-10 d-none d-md-block"
          outlined>
          <div class="pos-rlt">
            <v-row no-glutters>
              <v-col cols="6">
                <div class="pl-16 ">
                  <p class="subtitle-2 text-uppercase">What's special</p>
                  <p class="secondary-font-new mb-8">
                    Zebu Makes Investing <br>
                    Easy. Simple.
                  </p>
                  <v-card @click="changeTois(l)" v-for="(l, k) in datas" :key="k" data-aos="fade-down"
                    :color="activelist.id == k ? '#444444' : '#ffffff'" width="100%"
                    class="box-s1 brd-r-16 py-3 px-6 mb-4">
                    <p class="mb-0 title font-weight-regular"
                      :class="activelist.id == k ? 'white--text' : 'black--text'">{{ l.tit }}</p>
                  </v-card>
                </div>
              </v-col>
              <v-col cols="6">
                <div class="px-16">
                  <img data-aos="fade-up" :alt="activelist.image" :src="activelist.image" width="100%" />
                  <div data-aos="fade-down">
                    <p class="font-weight-medium mt-3 headline black--text">{{ activelist.tit }}</p>
                    <p class="font-weight-light mt-3 black--text fs-17">{{ activelist.sub }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
        <div class="d-md-none ">
          <p class="subtitle-2 text-uppercase">What's special</p>
          <p class="secondary-fn fs-28 mb-8 lh-32">Zebu makes stock investing <br>
            Easy. Simple.</p>
          <div v-for="(l, k) in datas" :key="k" :class="k != datas.length - 1 ? 'mb-16' : ''">
            <img data-aos="fade-up" :alt="l.image" :src="l.image" width="100%" />
            <div data-aos="fade-down">
              <p class="font-weight-medium fs-24 black--text">{{ l.tit }}</p>
              <p class="font-weight-light black--text fs-18">{{ l.sub }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="px-6 mt-14 px-sm-8 mx-16 mb-10">
      <v-card width="100%" data-aos="fade-down" color="#E5EBEC"
        class="box-s1 elevation-0 rounded-xl pt-8 pb-10 d-none d-md-block" outlined>
        <div class="pos-rlt">
          <p class="mb-0 fs-24 text-center black--text font-weight-medium">Frequently Asked Questions</p>

          <p class="font-weight-light text-center mt-2 black--text fs-18">Questions on your mind? Don't
            worry we have
            the
            answers!</p>

          <div v-if="mffaqgres != ''">
            <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-0 pl-8 mt-2 pr-8 mb-md-0">
              <v-expansion-panel class="bgstyle" v-for="(p, h) in mffaqgres" :key="h">
                <v-expansion-panel-header @click="setScrollwindow()" class="font-weight-bold bgstyle py-0 ml-md-0">
                  <span class="fs-18">{{ h + 1 }}. {{ p.question }}</span>
                  <template v-slot:actions>
                    <v-icon class="d-none d-md-block">mdi-chevron-down</v-icon>
                    <v-icon class="d-md-none">mdi-chevron-down</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="bgstyle">
                  <p class="mb-0 ml-5 fs-17 font-weight-light" v-html="p.answer"></p>
                </v-expansion-panel-content>
                <v-divider></v-divider>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-else class="mx-auto text-center ">
            <img class="align-self-stretch mx-auto" width="100px" :src="require('@/assets/no data folder.svg')" />
            <h4 class="txt-999 font-weight-regular">There is no data here yet!</h4>
          </div>
        </div>
      </v-card>
    </div>

    <div class="d-md-none ">
                <p class="mb-0 fs-24 ml-8 black--text font-weight-medium">Frequently Asked Questions</p>

                <p class="font-weight-light ml-8 mt-3 black--text fs-17">Questions on your mind? Don't worry we have the
                    answers!
                </p>
      
                <div v-if="mffaqgres != ''">
                    <v-expansion-panels dense v-model="panelselecte" class="elevation-0 rounded-0 mb-6 ml-3 mb-md-0">
                        <v-expansion-panel class="exp-sty" v-for="(p, h) in mffaqgres" :key="h">
                            <v-expansion-panel-header @click="setScrollwindow()"
                                class="font-weight-bold subtitle-1 py-0 ml-md-3">{{ h + 1
                                }}.
                                {{ p.question }}
                                <template v-slot:actions>
                                    <v-icon class="d-none d-md-block"></v-icon>
                                    <v-icon class="d-md-none">mdi-chevron-down</v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p class="mb-0 font-weight-light" v-html="p.answer"></p>
                            </v-expansion-panel-content>
                            <v-divider></v-divider>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-else class="mx-auto text-center ">
                    <img class="align-self-stretch mx-auto" width="100px"
                        :src="require('@/assets/no data folder.svg')" />
                    <h4 class="txt-999 font-weight-regular">There is no data here yet!</h4>
                </div>
            </div>


    <div class="pb-md-10 rounded-xl">
      <!-- py-md-16 -->
      <!-- <v-card data-aos="fade-down" height="380px" class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens mb-16" color="#E5EBEC" width="100%"> -->
      <v-card data-aos="fade-down" height="140px"
        class="seceight-bg rounded-xl pl-14 elevation-0 d-none d-md-block overflow-hiddens" color="transparent"
        width="100%">
        <!-- <v-card width="100%" class="d-inline-flex elevation-0 pos-rlt" color="transparent">
            <v-card width="50%" class="elevation-0 py-16" color="transparent">
              <p class="c-secondary-font">
                Exceptional Performance. <br />
                Exceptional Results.
              </p>
              <p class="txt-444 font-weight-light fs-20 lh-24">
                Mynt is a sophisticated and <br />
                modern trading application.
              </p>
              <div class="rounded-0 d-inline-flex">
                <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#000" class="mr-2 rounded-lg elevation-0 hcardd">
                  <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img" />
                </v-card>
                <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#000" class="mr-2 rounded-lg elevation-0 hcardd">
                  <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img" />
                </v-card>
                <v-card target="_" rel="noopener noreferrer" height="45px" href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#000" class="rounded-lg elevation-0 hcardd">
                  <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img" />
                </v-card>
              </div>
            </v-card>
            <v-card width="60%" class="elevation-0 mr-4 overflow-hidden" color="transparent" data-aos="fade-up">
              <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-0" />
            </v-card>
          </v-card> -->
      </v-card>
      <!-- <v-card data-aos="fade-down" class="seceight-bg rounded-0 pt-14 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="#E5EBEC" width="100%"> -->
      <v-card data-aos="fade-down"
        class="seceight-bg rounded-0 pb-10 mb-10 px-6 px-sm-8 elevation-0 d-md-none text-center" color="transparent"
        width="100%">
        <!-- <v-card width="100%" class="elevation-0" color="transparent">
            <v-card width="100%" class="elevation-0" color="transparent">
              <p class="c-secondary-font">Exceptional Performance. Exceptional Results.</p>
              <p class="txt-444 font-weight-light fs-20 lh-24">Mynt is a sophisticated and modern trading application.</p>
            </v-card>
            <v-card width="100%" class="elevation-0" color="transparent" data-aos="fade-up">
              <img src="@/assets/newimg.png" width="100%" alt="newimg" class="pa-2" />
            </v-card>
            <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://play.google.com/store/apps/details?id=com.zebu.mynt" color="#282828" class="rounded-lg elevation-0 mb-3">
              <img src="@/assets/product/scr-mob1.svg" class="px-1 pt-1" alt="playstore-btn-img" />
            </v-card>
            <v-card target="_blank" rel="noopener noreferrer" height="45px" href="https://apps.apple.com/in/app/mynt-zebu/id1663709767" color="#282828" class="rounded-lg elevation-0 mb-3">
              <img src="@/assets/product/scr-mob2.svg" class="px-1 pt-1" alt="playstore-btn-img" />
            </v-card>
            <v-card target="_" rel="noopener noreferrer" height="45px" href="https://zebu.mynt.in/static/Downloads/Apps/ZEBU_INV.exe" color="#282828" class="rounded-lg elevation-0">
              <img src="@/assets/product/scr-mob3.svg" class="px-1 pt-1" alt="web-btn-img" />
            </v-card>
          </v-card> -->
      </v-card>
      <div data-aos="fade-up" class="secineight-bg mb-8 mb-md-0">
        <v-card color="transparent" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-none d-md-block"
          width="90%">
          <img src="@/assets/sec-eight-bg.webp" width="100%" alt="sec-eight-bg" />

          <div class="seceight-ctn pos-abs">
            <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
              <v-row no-gutters class="pt-10">
                <v-col cols="9" class="pr-4">
                  <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()"
                    :maxlength="10" :rules="numberis" required hide-spin-buttons flat solo background-color="#595959"
                    class="menu-field-seceight elevation-0 mr-3 rounded-pill caption txt-field"
                    label="Enter mobile number to begin">
                    <template #prepend-inner>
                      <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon" />
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
                    class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                      up</span></v-btn>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card>
        <v-card color="#282828" class="secineight-card pos-rlt mx-auto brd-r-32 elevation-0 d-md-none pa-6" width="90%">
          <img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg" />
          <!-- <v-img src="@/assets/Msec-eight-bg.svg" width="100%" alt="sec-eight-bg"></v-img> -->

          <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="getCall()">
            <v-text-field v-model="mobile" @keypress="NumberValid($event)" @keyup.enter="getCall()" :maxlength="10"
              :rules="numberis" required hide-spin-buttons flat solo background-color="#595959"
              class="menu-field-seceight elevation-0 rounded-pill caption txt-field"
              label="Enter mobile number to begin">
              <template #prepend-inner>
                <img src="@/assets/phone-icon white.svg" width="100%" class="mx-2" alt="phone-icon" />
              </template>
            </v-text-field>
            <v-btn type="submit" block height="48px" :color="valid ? '#FFF07E' : '#FFF07Ec2'"
              class="text-none rounded-pill elevation-0"><span class="black--text font-weight-bold">Sign
                up</span></v-btn>
          </v-form>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    datas: [
      { id: 0, image: require("@/assets/invest/inv-p-l1.png"), tit: "Potential for High Returns", sub: "Investing in stocks for the long term is a reliable strategy to build wealth and gain significant returns over time." },
      { id: 1, image: require("@/assets/invest/inv-p-l2.png"), tit: "Portfolio Diversification", sub: "Traditional investments often fall short in delivering ideal returns. Diversifying with equity investments through stocks and mutual funds can provide the opportunity for better-than-average returns" },
      { id: 2, image: require("@/assets/invest/inv-p-l3.png"), tit: "High Liquidity", sub: "Unlike bonds and fixed deposits, stocks and mutual funds provide superior liquidity with near-instant price discovery, ensuring your assets remain easily accessible." },
      { id: 3, image: require("@/assets/invest/inv-p-l4.png"), tit: "Dividend Yield", sub: "Passive income is a growing trend, and dividend investing offers a way to achieve it. By investing in reliable, dividend-paying companies, you can build a steady stream of income over time." },
    ],
    activelist: {},

    valid: true,
    mobile: "",
    numberis: [(v) => !!v || "Your mobile number is required", (v) => /^[6-9][0-9]{9}$/.test(v) || "mobile number must be 10 Digit Number"],
    mffaqgres: [],
    panelselecte:""
  }),

  mounted() {
    this.activelist = this.datas[0];
    this.mffaqapi()

  },
  methods: {
    NumberValid($event) {
      if (($event.keyCode >= 48 && $event.keyCode <= 57) || ($event.keyCode >= 96 && $event.keyCode <= 105)) {
        // 0-9 only
      } else {
        $event.preventDefault();
      }
    },
    getCall() {
      if (this.$refs.form.validate() == true) {
        window.open(`https://oa.mynt.in/?ref=zws&mobile=${this.mobile}`);
        this.$refs.form.reset();
      }
    },
    changeTois(l) {
      this.activelist = l;
    },
    mffaqapi() {
      const axios = require('axios');

      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: 'https://sess.mynt.in/strapi/wsfaqs',
        headers: {}
      };
      var axiosThis = this
      axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
          if (response.data && response.data.data) {
            axiosThis.onlinepay(response.data.data[0].attributes);
          }
          // axiosThis.onlinepay = response.data.data.attributes.mffaq
          // console.log("axiosThis.onlinepay",axiosThis.onlinepay);

        })
        .catch((error) => {
          console.log(error);
        });


    },
    onlinepay(datas) {
      if (datas) {
        this.mffaqgres = datas.stockfaq


      }
    },
  },
};
</script>
