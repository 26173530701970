<template>
    <div class="px-0 px-md-14">
        <div>
            <v-card width="100%" class="price-card-sty elevation-0 rounded-c-xl mb-md-6 overflow-hidden">
                <div class="pb-md-8 px-6 px-sm-8 py-10">
                    <p class="mb-6 font-weight-bold secondary-infont fs-32 lh-32">CAGR Calculator</p>
                    <v-row no-gutters>
                        <v-col cols="12" md="6" class="pr-md-16">
                            <p class="mb-0 title font-weight-regular">Initial Investment
                            </p>
                            <v-text-field type="number" @keyup="cagrcalculator()" hide-spin-buttons dense
                                v-model="Principleammount" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-6" prefix="₹" hide-details>
                            </v-text-field>

                            <p class="mb-0 title font-weight-regular">Final investment (Maturity)</p>
                            <v-text-field type="number" @keyup="cagrcalculator()" hide-spin-buttons dense
                                v-model="InterestRate" block flat solo background-color="#ffffff" min="1"
                                class="calc-field elevation-0 rounded-pill mb-3" prefix="₹" hide-details>
                            </v-text-field>

                            <v-row class="mt-6">
                                <v-col cols="9">
                                    <p class="mb-0 title font-weight-regular">Duration of Investment</p>
                                </v-col>
                                <v-col cols="3" class="pt-2">
                                    <v-text-field block flat solo background-color="#ffffff"
                                        class="calc-field elevation-0 rounded-pill" hide-details dense
                                        @keyup="cagrcalculator()" type="number" suffix="Yr" v-model="TenurePeriodYear"
                                        hide-spin-buttons min="1" max="50" step="1"></v-text-field>
                                </v-col>
                                <v-col cols="12" class="pt-0">
                                    <v-slider @change="cagrcalculator()" v-model="TenurePeriodYear" ticks="always"
                                        tick-size="6" min="1" max="50" step="1" class='rounded-pill slider-range'
                                        track-color="#EEEEEE" thumb-color="#015FEC" hide-details color='#A3C8FF'></v-slider>

                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                            <p class="title font-weight-regular">Estimation</p>
                            <v-card class="elevation-0 rounded-lg d-inline-flex overflow-hidden" height="45px" width="100%"
                                color="transparent">
                                <v-card class="elevation-0 rounded-r-0 rounded-l-lg m-1" height="45px"
                                    :width="`${100 - emireport}%`" color="#AAAAAA"></v-card>

                                <v-card v-if="emireport" class="elevation-0 rounded-l-0 rounded-r-lg d-inline-flex"
                                    height="45px" :width="`${emireport}%`" color="transparent">
                                    <div v-for="n in 1000" :key="n" class="pad-x-2">
                                        <v-card class="elevation-0 rounded-lg d-inline-flex" height="45px" width="10px"
                                            color="#015FEC">
                                        </v-card>
                                    </div>
                                </v-card>
                            </v-card>

                            <v-list-item class="pa-0 mt-8">
                                <v-card height="54px" width="6px" class="elevation-0 rounded-pill mr-3"
                                    color="#015FEC"></v-card>
                                <v-list-item-content class="pa-0">
                                    <v-list-item-subtitle class="mb-2 fs-18 font-weight-regular">Total CAGR
                                        is</v-list-item-subtitle>
                                    <p class="mb-0 title font-weight-black">{{ emireport ?
                                        emireport : '0.00'
                                    }}<span class="subtitle-1 font-weight-light">%</span></p>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                </div>
            </v-card>
            <v-card width="100%" class="elevation-0 rounded-c-xl overflow-hidden" outlined>
                <div class="px-6 px-sm-8 px-md-10 py-6">
                    <p class="mb-0 font-weight-bold title">What is CAGR?</p>

                    <p class="mb-6 font-weight-light txt-444">CAGR is a way to figure out how much your investments have
                        grown on average each year over a certain time period. It tells you how much your investments have
                        earned on average over a year. The CAGR is a useful tool for investors because it shows exactly how
                        investments grow (or shrink) over time. When figuring out CAGR, it is assumed that profits are put
                        back into the business at the end of each year. So, CAGR is a number that represents the compounded
                        return, not the return itself. Usually, you can't expect an investment to grow at the same rate
                        every year. Even so, many people use the CAGR calculator to compare different
                        investments.<br><br>

                        With this common use of the calculation in mind, investors would do well to find an easy way to
                        figure out CAGR. The CAGR calculator can be used by anyone who wants to figure out what their return
                        on investment will be. The formula for the Compound Annual Growth Rate is used to figure out the
                        CAGR. For instance, if you have a mutual fund that has gone up in value over time, you can use the
                        calculator to figure out your rate of return.</p>
                    <p class="mb-0 font-weight-bold title">What is a CAGR calculator?</p>
                    <p class="mb-6 font-weight-light txt-444">It lets investors figure out how much money they will make
                        in different situations. Several test cases can be used to measure returns in different situations.
                        <br><br>
                        It's easy to figure out how to use. The online CAGR calculator only needs to know the initial value,
                        rate of interest, and the amount of time you want to invest for. It will take care of the rest.
                        <br><br>
                        Let's say you bought some units of an equity fund in the past and their value has gone up since
                        then. Using the CAGR online calculator, you can figure out how much money you made on your
                        investment.
                        <br><br>
                        It gives you a full picture of how much money you're making back.
                        <br><br>
                        You can also use the calculator to compare the performance of a stock to that of its peers or the
                        industry as a whole.
                    </p>

                    <p class="mb-0 font-weight-bold title">How CAGR is calculated?</p>
                    <p class="font-weight-light txt-444">
                        Use the CAGR calculation formula and do the following steps to figure out the compounded annual
                        growth rate on an investment:
                        <br><br>
                        Mathematically, the CAGR formula is given by the following equation:
                        <br><br>
                        CAGR = (B / A) 1 / n - 1
                        <br><br>
                        In the above formula, B stands for the value of the investment in the future, A stands for the value
                        of the investment in the present, and n stands for the number of years of investment.
                        <br><br>
                        You can also use the CAGR calculator to figure out the absolute return on investment. This is how to
                        figure it out:
                        <br><br>
                        Absolute returns = (B - A) / A * 100
                    </p>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            Principleammount: 10000,
            InterestRate: 25000,
            TenurePeriodYear: 2,
            emireport: 0,
        }
    },
    mounted() {
        this.cagrcalculator()
    },

    methods: {
        cagrcalculator() {
            if (this.Principleammount > 0 && this.InterestRate > 0 && this.TenurePeriodYear > 0) {
                // console.log("html input data : ", this.Principleammount, this.InterestRate, this.TenurePeriodYear)
                var first = (this.InterestRate / this.Principleammount)
                // console.log("first", first)

                var second = Math.pow(first, 1 / this.TenurePeriodYear) - 1
                // console.log("second", second)

                var cagr = (second * 100).toFixed(2);
                // console.log("CAGR", cagr)
                this.emireport = cagr;
            }
        },
    }

}
</script>